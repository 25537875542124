import { defineRouting } from 'next-intl/routing';
import { createNavigation } from 'next-intl/navigation';
import { languageMap } from '@/utils/languages';

export const routing = defineRouting({
  // A list of all locales that are supported
  locales: Object.keys(languageMap),

  // Used when no locale matches
  defaultLocale: 'en',
  localeDetection: true,
  localePrefix: 'as-needed',
});

// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
export const { Link, redirect, usePathname, useRouter, getPathname } =
  createNavigation(routing);
