export const getDefaultLanguage = (): string => {
  return String(navigator.language).toLowerCase().slice(0, 2);
};

export const languageMap = {
  en: { code: 'EN', full: 'English' },
  es: { code: 'ES', full: 'Español' },
  zh: { code: 'ZH', full: '中文 (简体)' },
  ar: { code: 'AR', full: 'العربية' },
  fr: { code: 'FR', full: 'Français' },
  ru: { code: 'RU', full: 'Русский' },
  pt: { code: 'PT-BR', full: 'Português (Brasil)' },
  id: { code: 'ID', full: 'Bahasa Indonesia' },
  it: { code: 'IT', full: 'Italiano' },
  tr: { code: 'TR', full: 'Türkçe' },
  pl: { code: 'PL', full: 'Polski' },
  ko: { code: 'KO', full: '한국어' },
  ro: { code: 'RO', full: 'Română' },
  el: { code: 'EL', full: 'Ελληνικά' },
  hu: { code: 'HU', full: 'Magyar' },
};
